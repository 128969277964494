<template>
  <v-card flat class="inspection-card" id="inspection-queen-age-card">
    <v-row>
      <v-col cols="12">
        <div class="inspection-card-title">{{$t('queenAge')}}</div>
      </v-col>
    </v-row>

    <NoData v-if="hasNoData"></NoData>
    <div class="queenAge-chart-container" v-else>
      <v-row class="status-row">
        <v-col cols=2 class="d-flex align-center justify-end py-2">
          <p class="status-percentage">{{thisYear}}</p>
        </v-col>
        <v-col cols=10 class="ma-auto">
          <div class="progress-container">
            <v-progress-linear
              color="#03cfbb"
              background-color="#ecedf480"
              :value="thisYear"
              height="25"
              rounded
              reverse
              bottom
              class="my-progress-bar"
            ></v-progress-linear>
          </div>
        </v-col>
      </v-row>
      <v-row class="status-row">
        <v-col cols=2 class="d-flex align-center justify-end py-2">
          <p class="status-percentage">{{lastYear}}</p>
        </v-col>
        <v-col cols=10 class="ma-auto">
          <div class="progress-container">
            <v-progress-linear
              color="#2aa8f8"
              background-color="#ecedf480"
              :value="lastYear"
              height="25"
              rounded
              reverse
              bottom
              class="my-progress-bar"
            ></v-progress-linear>
          </div>
        </v-col>
      </v-row>
      <v-row class="status-row">
        <v-col cols=2 class="d-flex align-center justify-end py-2">
          <p class="status-percentage">{{older}}</p>
        </v-col>
        <v-col cols=10 class="ma-auto">
          <div class="progress-container">
            <v-progress-linear
              color="#fd6150"
              background-color="#ecedf480"
              :value="older"
              height="25"
              rounded
              reverse
              bottom
              class="my-progress-bar"
            ></v-progress-linear>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <div class="mr-4 mr-sm-8">
          <v-icon color="#03cfbb" small>mdi-circle</v-icon>
          <span class="legend-title">{{$t('this_year')}}</span>
          <span class="legend-number">{{legendThisYear}}</span>
        </div>
        <div class="mr-4 mr-sm-8">
          <v-icon color="#2AA8F8" small>mdi-circle</v-icon>
          <span class="legend-title">{{$t('last_year')}}</span>
          <span class="legend-number">{{legendLastYear}}</span>
        </div>
        <div>
          <v-icon color="#FD6150" small>mdi-circle</v-icon>
          <span class="legend-title">{{$t('older')}}</span>
          <span class="legend-number">{{legendOlder}}</span>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import NoData from '@/components/InspectionAnalysis/NoData.vue';

  export default {
    name: 'QueenAge',
    components: {
      NoData
    },
    computed: {
      ...mapGetters([
        "apiaryHives",
        "selectedApiary",
        "selectedInspectionDateObj",
        "inspectionQueenAge"
      ]),
      hasNoData() {
        return this.inspectionQueenAge.length === 0;
      },
      legendThisYear() {
        let result = 0;
        if (!this.hasNoData) {
          result = this.inspectionQueenAge[0].count;
        }
        return result;
      },
      legendLastYear() {
        let result = 0;
        if (!this.hasNoData) {
          result = this.inspectionQueenAge[1].count;
        }
        return result;
      },
      legendOlder() {
        let result = 0;
        if (!this.hasNoData) {
          result = this.inspectionQueenAge[2].count;
        }
        return result;
      },
      total() {
        return this.inspectionQueenAge.reduce((partialSum, a) => partialSum + a.count, 0);
      },
      thisYear() {
        let hiveCount = this.inspectionQueenAge[0].count
        let result = Math.round( ((hiveCount * 100) / this.total) * 100) / 100;
        return result + '%';
      },
      lastYear() {
        let hiveCount = this.inspectionQueenAge[1].count
        let result = Math.round( ((hiveCount * 100) / this.total) * 100) / 100;
        return result + '%';
      },
      older() {
        let hiveCount = this.inspectionQueenAge[2].count
        let result = Math.round( ((hiveCount * 100) / this.total) * 100) / 100;
        return result + '%';
      }
    },
    methods: {
      ...mapActions(["inspectionGroupMetrics"]),
      getInspectionQueenAge() {
        let payload = {
          apiary_id: this.selectedApiary.id,
          ig_id: this.selectedInspectionDateObj.id,
          field: 'queenColor'
        };
        this.inspectionGroupMetrics(payload);
      },
    }
  };
</script>

<style type="text/css">
  #inspection-queen-age-card .my-progress-bar,
  #inspection-queen-age-card .my-progress-bar .v-progress-linear__determinate {
    border-radius: 12px;
  }

  #inspection-queen-age-card .progress-container {
    height: 25px;
  }

  #inspection-queen-age-card .progress-container .v-progress-linear__background {
    right: 0 !important;
    width: 100% !important;
  }

  #inspection-queen-age-card p.status-percentage {
    margin: 0;
  }

  #inspection-queen-age-card .queenAge-chart-container {
    padding: 60px 0 72px;
    margin: 0;
  }

  #inspection-queen-age-card .progress-container:hover,
  #inspection-queen-age-card .progress-container:hover .v-progress-linear__determinate {
    box-shadow: 0 0 4px rgb(0 0 0 / 30%);
    border-radius: 12px;
  }

  #inspection-queen-age-card .status-row {
    margin-top: 27px;
  }
</style>