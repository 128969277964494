<template>
  <v-card flat class="inspection-card" id="inspection-hive-status-card">
    <v-row>
      <v-col cols="12">
        <div class="inspection-card-title">{{$t('hiveStatus')}}</div>
      </v-col>
    </v-row>

    <NoData v-if="hasNoData"></NoData>
    <v-row v-else>
      <v-col cols="12" id="inspection-status-chart">
        <GChart
          type="PieChart"
          :data="inspectionGeneralStatus"
          :options="chartOptions"
        />
        <div class="status-title-container">
          <span class="d-flex justify-center">
            <v-img
              contain
              height="24"
              width="24"
              src="@/assets/bee_smoker.png"
            ></v-img>
          </span>
          <br>
          <span class="inspection-hive-number">{{selectedInspectionDateObj.count}}</span>
        </div>
      </v-col>
    </v-row>
    
    <v-row class="pt-4">
      <v-col cols="12" class="d-flex justify-center">
        <div class="mr-8">
          <v-icon color="#03cfbb" small>mdi-circle</v-icon>
          <span class="legend-title">{{$t('excellent')}}</span>
          <span class="legend-number">{{legendExcellent}}</span>
        </div>
        <div class="mr-8">
          <v-icon color="#2AA8F8" small>mdi-circle</v-icon>
          <span class="legend-title">{{$t('good')}}</span>
          <span class="legend-number">{{legendGood}}</span>
        </div>
        <div>
          <v-icon color="#FD6150" small>mdi-circle</v-icon>
          <span class="legend-title">{{$t('bad')}}</span>
          <span class="legend-number">{{legendBad}}</span>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import { GChart } from 'vue-google-charts'

  import NoData from '@/components/InspectionAnalysis/NoData.vue';

  export default {
    name: 'HiveStatus',
    components: {
      GChart,
      NoData
    },
    computed: {
      ...mapGetters([
        "selectedApiary",
        "selectedInspectionDateObj",
        "inspectionGeneralStatus"
      ]),
      hasNoData() {
        return this.inspectionGeneralStatus.length === 0;
      },
      legendExcellent() {
        let result = 0;
        if (!this.hasNoData) {
          result = this.inspectionGeneralStatus[1][1];
        }
        return result;
      },
      legendGood() {
        let result = 0;
        if (!this.hasNoData) {
          result = this.inspectionGeneralStatus[2][1];
        }
        return result;
      },
      legendBad() {
        let result = 0;
        if (!this.hasNoData) {
          result = this.inspectionGeneralStatus[3][1];
        }
        return result;
      }
    },
    data: () => ({
      chartOptions: {
        legend: 'none',
        pieSliceText: 'none',
        pieHole: 0.6,
        slices: {
          0: { color: '#03CFBB' },
          1: { color: '#2AA8F8' },
          2: { color: '#FD6150' }
        },
        height: 260,
        chartArea: {
          width: '100%',
          height: '90%',
          top: 10,
          bottom: 10
        },
        tooltip: {
          isHtml: true,
          trigger: 'selection'
        }
      }
    }),
    mounted() {
      this.getInspectionGeneralStatus();
    },
    methods: {
      ...mapActions(["inspectionGroupMetrics"]),
      getInspectionGeneralStatus() {
        let payload = {
          apiary_id: this.selectedApiary.id,
          ig_id: this.selectedInspectionDateObj.id,
          field: 'generalStatus'
        };
        this.inspectionGroupMetrics(payload);
      },
    }
  }
</script>

<style type="text/css">
  #inspection-status-chart {
    position: relative;
  }

  #inspection-hive-status-card .status-title-container {
    position: absolute;
    top: 40%;
    left: 40%;
    text-align: center;
    width:  20%;
  }

  .inspection-status-title {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: #6E7191;
  }

  .inspection-hive-number {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: #14142B;
  }
</style>
