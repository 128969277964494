<template>
  <v-card flat class="inspection-card" id="inspection-honeycombs-card">
    <v-row>
      <v-col cols="12" class="pb-0">
        <div class="inspection-card-title">{{$t('honeycombs')}}</div>
      </v-col>
      <v-col cols="12" class="d-flex py-0">
        <v-row>
          <v-col class="d-flex">
            <span class="inspection-card-subtitle">{{$t('lowestFrames')}}</span>
            <span class="my-auto">
              <v-img
                contain
                height="6"
                width="8"
                class=""
                src="@/assets/arrow_down.svg"
              ></v-img>
            </span>
            <span class="inspection-card-subtitle-number">
              {{framesMin}}
            </span>
          </v-col>
          <v-col class="d-flex">
            <span class="inspection-card-subtitle">{{$t('highestFrames')}}</span>
            <span class="my-auto">
              <v-img
                contain
                height="6"
                width="8"
                class=""
                src="@/assets/arrow_up.svg"
              ></v-img>
            </span>
            <span class="inspection-card-subtitle-number">
              {{framesMax}}
            </span>
          </v-col>
          <v-col sm="3" class="d-none d-sm-flex"></v-col>
        </v-row>
      </v-col>
    </v-row>

    <NoData v-if="hasNoData"></NoData>
    <v-row v-else>
      <v-col cols="12" id="inspection-honeycombs-chart">
        <GChart
          type="PieChart"
          :data="inspectionHoneycombs.chartData"
          :options="chartOptions"
        />
        <div class="avg-title-container">
          <span class="inspection-avg-title">{{$t('avg')}}</span> <br> 
          <span class="inspection-avg-number">
            {{inspectionHoneycombs.average}}
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row class="pt-4">
      <v-col cols="12" class="d-flex justify-center">
        <div class="mr-8">
          <v-icon color="#03cfbb" small>mdi-circle</v-icon>
          <span class="legend-title">{{$t('above_avg')}}</span>
          <span class="legend-number">
            {{legendAbove}}
          </span>
        </div>
        <div>
          <v-icon color="#FD6150" small>mdi-circle</v-icon>
          <span class="legend-title">{{$t('below_avg')}}</span>
          <span class="legend-number">
            {{legendBelow}}
          </span>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import { GChart } from 'vue-google-charts'
  import NoData from '@/components/InspectionAnalysis/NoData.vue';

  export default {
    name: 'Honeycombs',
    components: {
      GChart,
      NoData
    },
    computed: {
      ...mapGetters([
        "selectedApiary",
        "selectedInspectionDateObj",
        "inspectionHoneycombs"
      ]),
      hasNoData() {
        return Object.keys(this.inspectionHoneycombs).length === 0;
      },
      legendAbove() {
        let result = 0;
        if (!this.hasNoData) {
          result = this.inspectionHoneycombs.chartData[2][1];
        }
        return result;
      },
      legendBelow() {
        let result = 0;
        if (!this.hasNoData) {
          result = this.inspectionHoneycombs.chartData[1][1];
        }
        return result;
      },
      framesMin() {
        let result = 0;
        if (!this.hasNoData) {
          result = this.inspectionHoneycombs.min;
        }
        return result;
      },
      framesMax() {
        let result = 0;
        if (!this.hasNoData) {
          result = this.inspectionHoneycombs.max;
        }
        return result;
      }
    },
    data: () => ({
      chartOptions: {
        legend: 'none',
        pieSliceText: 'none',
        pieHole: 0.6,
        slices: {
          0: { color: '#FD6150' },
          1: { color: '#03CFBB' }
        },
        height: 260,
        chartArea: {
          width: '100%',
          height: '90%',
          top: 10,
          bottom: 10
        },
        tooltip: {
          isHtml: true,
          trigger: 'selection'
        }
      }
    }),
    mounted() {
      this.getInspectionHoneycombs();
    },
    methods: {
      ...mapActions(["inspectionGroupMetrics"]),
      getInspectionHoneycombs() {
        let payload = {
          apiary_id: this.selectedApiary.id,
          ig_id: this.selectedInspectionDateObj.id,
          field: 'honeycombs'
        };
        this.inspectionGroupMetrics(payload);
      }
    }
  }
</script>

<style type="text/css">
  #inspection-honeycombs-chart {
    position: relative;
  }
</style>
