<template>
  <v-container fluid id="inspection-analysis">
    <v-row class="pa-2 pa-sm-6">
      <v-col cols="12" sm="12" md="6" lg="4">
        <v-card flat class="my-card">
          <h2>{{$t('inspectionAnalysis')}}</h2>
          <p class="inspection-paragraph">{{$t("inspectionAnalysis_text")}}</p>
          <v-row class="mt-5 mt-sm-7" align="center" justify="space-between">
            <v-col cols="12">
              <date-picker
                v-model="inspectionDate"
                :placeholder="$t('selectDate')"
                value-type="date"
                format="MMMM D, YYYY"
                :clearable="false"
                class="datepicker-container"
                popup-class="my-datepicker-popup"
                input-class="datepicker-btn"
                :lang="lang"
                :disabled-date="disabledDates"
              >
                <template v-slot:icon-calendar>
                  <v-img class="calendar-icon" src="@/assets/calendar.svg"></v-img>
                </template>
              </date-picker>
            </v-col>
          </v-row>
          <v-btn block outlined class="my-btn analyze-btn"
            @click="getInspectionData">
            {{$t('analyzeData')}}
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="8" class="pa-0">
        <InspectionAssessment class="mb-6" />
        
        <InspectionGrowth class="mb-6" />
      </v-col>

      <v-overlay :value="showLoader">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";

  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import 'vue2-datepicker/locale/el';
  import 'vue2-datepicker/locale/en';
  import 'vue2-datepicker/locale/es';

  import InspectionAssessment from '@/components/InspectionAnalysis/InspectionAssessment.vue';
  import InspectionGrowth from '@/components/InspectionAnalysis/InspectionGrowth.vue';

  export default {
    name: 'InspectionAnalysis',
    components: {
      DatePicker,
      InspectionAssessment,
      InspectionGrowth
    },
    computed: {
      ...mapGetters([
        "selectedApiary",
        "inspectionDates",
        "selectedInspectionDateObj"
      ]),
      inspectionDate: {
        get() {
          let date = this.$store.state.inspections.selectedInspectionDate;
          return new Date(date);
        },
        set(date) {
          let formattedDate = moment(date).format("YYYY/MM/DD");
          this.$store.dispatch('setSelectedInspectionDate', formattedDate);
        }
      },
      lang: {
        get() {
          return this.$i18n.locale;
        }
      },
    },
    data: () => ({
      showLoader: false,
      checkbox_off: "$vuetify.icons.checkbox_off",
      inspection_on: "$vuetify.icons.inspection_on"
    }),
    mounted() {
      this.setIsHiddenApiaryButton(false);
    },
    methods: {
      ...mapActions([
        "getApiaryHives",
        "resetCharts",
        "inspectionGroupMetrics",
        "setIsHiddenApiaryButton"
      ]),
      disabledDates(date) {
        let tempDate = moment(date).format("YYYY/MM/DD");
        let matchedDates = false;
        for (let k = 0; k < this.inspectionDates.length; k++) {
          let inspectionDate = this.inspectionDates[k].date;
          if (tempDate == inspectionDate) {
            matchedDates = true;
            break;
          }
        }
        if (!matchedDates) {
          return new Date(tempDate);
        }
      },
      getInspectionData(date) {
        this.showLoader = true;
        let promises = [];
        let metrics = [
          'generalStatus',
          'queenColor',
          'populationFrames',
          'broodFrames',
          'honeycombs',
          'harvestedFrames'
        ];

        let inspectionGroupId = this.selectedInspectionDateObj.id;

        this.resetCharts().then(() => {
          for (let k = 0; k < metrics.length; k++) {
            let promise = this.getInspectionMetric(metrics[k], inspectionGroupId);
            promises.push(promise);
          }

          Promise.all(promises).then((res) => {
            this.showLoader = false;
            this.$gtag.event(
              'Inspection Analysis',
              {
                'event_category': 'Analytics'
              }
            );
          });
        });
      },
      getInspectionMetric(field, inspectionGroupId) {
        return new Promise((resolve, reject) => {
          let payload = {
            apiary_id: this.selectedApiary.id,
            ig_id: inspectionGroupId,
            field: field
          };
          this.inspectionGroupMetrics(payload).then(() => {
            resolve();
          }).catch(er => {
            reject(er);
          });
        });
      }
    }
  }
</script>

<style type="text/css">
  .datasets {
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: 0.75px;
  }

  .inspection-paragraph {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #6E7191;
  }

  #inspection-analysis p.inspection-paragraph {
    margin: 15px 0 30px;
  }

  .hives-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.75px;
  }

  .hive-count {
    background-color: #eaac30;
    text-align: center;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    padding: 3px 8px;
    width: 33px;
    height: 28px;
  }

  .inspection-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #262338;
  }

  .inspection-label:hover {
    cursor: pointer;
  }

  #inspection-analysis .my-checkbox {
    margin: 0 0 0 10px;
    padding: 0;
  }

  #inspection-analysis .my-card button.analyze-btn {
    font-size: calc(15px + (20 - 15) * ((100vw - 375px) / (1440 - 375)));
    line-height: 32px;
    letter-spacing: 0.75px;
    padding: 20px 24px;
    margin-top: 80px;
    height: 65px;
  }

  #inspection-analysis .my-card button.analyze-btn:hover {
    background-color: #262338;
    color: #ffffff;
  }
  
  button.analyze-btn:hover::before {
    opacity: 0;
  }
  
  #inspection-analysis .inspection-card {
    padding: 25px 40px 30px;
    border-radius: 8px;
  }

  .inspection-card-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 1px;
    color: #1A1A1A;
  }

  .inspection-card-subtitle {
    font-size: 10px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #6E7191;
    margin-right: 5px;
  }

  .inspection-card-subtitle-number {
    font-weight: 600;
    font-size: 10px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
    margin-left: 5px;
  }

  .inspection-dataset-title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: #262338;
    margin-left: 10px;
  }

  #inspection-analysis .legend-title {
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #4E4B66;
    margin-left: 6px;
  }

  #inspection-analysis .legend-number {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #4E4B66;
    margin-left: 6px;
  }

  @media screen and (max-width: 960px) {
    #inspection-analysis .my-card button.analyze-btn {
      margin-top: 50px;
    }
  }

  @media screen and (max-width: 600px) {
    #inspection-analysis .inspection-card {
      padding: 25px;
    }
  }
</style>
