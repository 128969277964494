<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="6">
        <PopulationFrames />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6">
        <BroodFrames />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6">
        <Honeycombs />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6">
        <HarvestedFrames />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import PopulationFrames from '@/components/InspectionAnalysis/PopulationFrames.vue';
  import BroodFrames from '@/components/InspectionAnalysis/BroodFrames.vue';
  import Honeycombs from '@/components/InspectionAnalysis/Honeycombs.vue';
  import HarvestedFrames from '@/components/InspectionAnalysis/HarvestedFrames.vue';

  export default {
    name: 'InspectionGrowth',
    components: {
      PopulationFrames,
      BroodFrames,
      Honeycombs,
      HarvestedFrames
    },
    data: () => ({
      inspection_on: "$vuetify.icons.inspection_on",
    })
  }
</script>
