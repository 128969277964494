<template>
  <v-row class="no-data-container">
    <v-col>
      <v-img
        contain
        height="44"
        width="44"
        class="ma-auto"
        src="@/assets/database.svg"
      ></v-img>
      <p class="no-data-text">{{$t('noAvailableData')}}</p>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'NoData',
    data: () => ({

    })
  };
</script>

<style type="text/css" scoped>
  .no-data-container {
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 100px 0 65px;
  }

  .no-data-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #4E4B66;
    margin-top: 25px;
  }
</style>