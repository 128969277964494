<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="6">
        <HiveStatus />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6">
        <QueenAge />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import HiveStatus from '@/components/InspectionAnalysis/HiveStatus.vue';
  import QueenAge from '@/components/InspectionAnalysis/QueenAge.vue';

  export default {
    name: 'InspectionAssessment',
    components: {
      HiveStatus,
      QueenAge
    },
    data: () => ({
      inspection_on: "$vuetify.icons.inspection_on",
    })
  }
</script>
